.picture {
  height: 150px;
  width: 150px;
  border-radius: 5px;
  object-fit: cover;
  margin: 10px auto;
}

.button {
  width: 100%;
}

.container {
  height: calc(100vh - 170px);
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.buttonsContainer{
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
