.popup-header {
    width: 100%;
    border-bottom: 1px solid gray;
    font-size: 18px;
    text-align: center;
    padding: 5px;
}

.close {
    cursor: pointer;
    position: absolute;
    display: block;
    padding: 2px 5px;
    line-height: 20px;
    right: -10px;
    top: -10px;
    font-size: 24px;
    background: #ffffff;
    border-radius: 18px;
    border: 1px solid #cfcece;
}

.content {
    width: 100%;
    padding: 10px 5px;
    text-align: center;
}

.actions {
    width: 100%;
    padding: 10px 5px;
    margin: auto;
    text-align: center;
}

.popup-btn {
    padding: 10px 20px;
    border-radius: 20px;
}

.popup-content{
    @media screen and (max-width: 600px){
        width: 90%;
    }
}

.popup-icon{
    width: 20px;
    height: 20px;
}

.btn-small{
    height: 50px!important;
}
.label-tel{
    text-align: center;
    margin-bottom: 8px;
}
.input-tel{
    text-align: center;
    input{
        width: 80%;
        height: 30px;
        text-align: center;
    }
}

.icon-size-invitation{
    font-size: 30px;
}