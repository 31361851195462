@font-face {   
    font-family: 'DfA';    //This is what we are going to call
    src: url('./fonts/AccessibleDfA-.ttf');
}
@font-face {   
    font-family: 'DfA-bold';    //This is what we are going to call
    src: url('./fonts/AccessibleDfA-bold.ttf');
}
@font-face {   
    font-family: 'DfA-italic';    //This is what we are going to call
    src: url('./fonts/AccessibleDfA-italic.ttf');
}

html.md{
    --ion-default-font: "DfA";
}

.ios {
    --ion-default-font: "DfA";
}

ion-label{
    font-size: 20px!important;
}

/*
ion-button{
    height: 50px!important;
}
*/

.menu-content-open{
    pointer-events:all!important;
}



ion-button{
    height: 80px!important;
}

ion-toast::part(message){
    font-size: 22px!important;
    text-align: center;
}


ion-footer{
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
}

/*https://trello.com/c/1P3HAGoY/239-largeur-de-la-liste-des-noms-trop-grande-qui-ne-permet-pas-ais%C3%A9ment-de-valider-en-cliquant-sur-le-bouton*/
.modal-wrapper{
    width: 100%!important;
    height: 100%!important;
}



.full-height-flex
{
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;

    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;

    height: 100%;
}

/* Hide element for small device*/
@media screen and (max-width: 700px) {
    .hide-sm {
      display: none;
    }
  }


ion-page {
  overflow: hidden;
  
}

ion-content 
{
  /*--offset-bottom: auto!important;*/
  overflow:auto;
  
 }


 ion-button::part(native)
 {
     border-radius: 0!important;
 }




/*For svg icon*/
.svg-size
{
    position: relative;
    height: var(--ion-svg-height);
    @media screen and (max-width: 766px){
        height: var(--ion-svg-height-1);
    }
}
.svg-home
{ 
    height: 50px;
   
}

.svg-size > img
{
    height: 100%;
    margin-right:6px;
    margin-left:6px;
}

.svg-size .icon-hover
{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top:0;
}
.helped-profil {
    @media screen and (min-width: 768px){
        ion-page {
            overflow: hidden;
          }
          
          ion-content 
          {
            --offset-bottom: auto!important;
            overflow:auto;
           }
          ion-content::-webkit-scrollbar
          {
              width: 45px;
              background-color: #F5F5F5;
          }
          
          ion-content::-webkit-scrollbar-thumb
          {
              border-radius: 10px;
              -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
              box-shadow: inset 0 0 6px rgba(0,0,0,.3);
              background-color: #f19300;
          }
    
          /** Scroll Bar  **/
            ion-content::-webkit-scrollbar
            {  display: block;
                width: 45px;
                
            }
    
    
            ion-content::-webkit-scrollbar-track
            {
                -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
                box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
                border-radius: 10px;
                background-color: #F5F5F5;
            }
    }
    
      
}

.padding-bottom {
    padding-bottom: 145px;
}

.border-green{
    border: 3px solid #439423;
}

.center-flex {
    display: flex;
    align-items: center;
    justify-content: center;
}
