.mediaContainer{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 100%;
}

.image{
    max-height: calc(100% - 65px);
}

.video{
    max-height: 100%;
    max-width: 100%;
}