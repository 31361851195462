
.small{
    font-size: 14px !important;
}

.medium{
    font-size: 19px !important;
}

.large{
    font-size: 22px !important;
}