#login-page, #signup-page, #support-page {
  .login-logo {
    padding: 20px 0;
    min-height: 200px;
    text-align: center;
  }

  .login-logo img {
    max-width: 150px;
  }

  .list {
    margin-bottom: 0;
  }

  .lost-password{
  display: flex;
    align-items: center;
    justify-content: center;
  font-size: 27px;
  }
  .title-center{
    text-align: center;
  }

}


