#jitsi-container {
  flex-grow: 1;
}
#visio-call-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.call-toolbar {
  width: 100%;
}

.hide {
  display: none;
}

.btn-hangout-call {
  font-size: 3vh;
}

ion-button::part(native) {
  padding: 5px;
  .button-inner {
    flex-flow: column !important;
  }
}

.preview-user {
  img {
    height: 150px;
    border-radius: 50%;
    width: 150px;
    margin-bottom: 15px;
    object-fit: cover;
  }
}

.visio-call-title {
  padding: 5px;
  box-shadow: 0px 2px rgba(0, 0, 0, 0.1);
  z-index: 11;
  display: flex;
  justify-content: flex-end;
}

.invitation-icon{
  height: 40px;
  width: 40px;
  float: right;
}

.invitation{
  display: flex;
  flex-direction: column;
  align-items: center;
  
}
