.center-title-header-card {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.blueBorder {
  border: 3px solid #195ea4;
}

.cardHeaderTextMessage{
  top: 0px;
  left: 0px;
  right: 0px;
  position: absolute;
  background-color: rgba(6, 4, 4, 0.5);
  color: white;
  font-size: 18px;
  padding: 8px;
  padding-top: 10px;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;
}