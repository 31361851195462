.audio-record-icon{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: red;
    margin: 15px;
}
.record{
    animation-name: pulse;
	animation-duration: 1.5s;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
}

.record-container{
    text-align: center;
    display: flex;
    color: red;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.timer{
    font-size: 50px !important;

}

.audio-record-container{
    flex-grow: 1;
    text-align: center;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.recorded-audio{
    margin: 0 auto;
    width: 80%;
}

.timer-container{
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.buttons-container{
    padding: 20px;
}