.weatherContent
{
    display: flex;
    justify-content:space-around;
    
}
.weatherContainer{
    padding: 10px;
}
.weatherTemp
{
    font-size: 4.5vw;
    margin-right: 20px;
}

.weatherDesc{
    font-size: 3vw;
}

.weatherIcon > img{
    height: 12vw;
}