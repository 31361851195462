.iconTitle {
  font-size: var(--ion-subtitle-font-size);
  @media screen and (max-width: 766px) {
    font-size: var(--ion-subtitle-font-size-1);
  }
  color: #424242;
}

.headerTitle {
  overflow-wrap: normal!important;
  font-weight: bold;
  text-align: center; 
  margin: 0;
  @media screen and (max-width: 765px) {
    width: 100%;
    padding: 5px;
    position: relative;
  }
  @media screen and (min-width: 766px) {
   z-index: 999;
   width: 60%;
   padding: 0px;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   position: absolute;
  }
  
}

