.icon-size {
  font-size: 55px;
}

.icon-size-mid {
  font-size: 36px;
}

.render-video {
  height: 100%;
  display: flex;
  justify-content: center;
  flex: 1;
}

ion-avatar.picture {
  height: 70px;
  width: 70px;
}

ion-avatar.picture > img {
  border-radius: 5px !important;
}

.card-header-text-folder {
  bottom: 0px;
  left: 0px;
  right: 0px;
  position: absolute;
  background-color: rgba(6, 4, 4, 0.5);
  color: white;
  font-size: 18px;
  padding: 8px;
  padding-top: 10px;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
}

.card-header-folder {
  top: 0px;
  left: 0px;
  right: 0px;
  position: absolute;
  color: white;
  font-size: 12px;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  z-index: 10;
  @media screen and (max-width: 304px){
    font-size: 10px;
  }
}

.card-header-folder-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: rgba(6, 4, 4, 0.5);
  padding: 8px;
}

.folder-card ion-card-content {
  padding: 5px !important;
  text-align: center;
}

.folder-card ion-col {
  padding: 0px !important;
}

.folder-card ion-button {
  border-radius: 0px !important;
}

.flex-end {
  align-self: flex-end;
  text-align: end;
}

.new-badge {
  align-self: flex-start;
  margin-top: 5px;
  background-color: red;
  border-radius: 5px;
  padding: 3px 5px;
  text-align: start;
}

.heart-icon {
  float: right;
  width: 40px;
  height: 40px;
  color: #f19300;
}

.border-green{
  border: 3px solid #439423;
}

.title-header{
  //position: absolute;
  //left: 50%;
  //transform: translate(-50%);
  margin: 0 auto;
}
