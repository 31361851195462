.env-developpement
{
  position: relative;
  top:-37px;
  left:0px;
  color:red;
  font-size: 15px;
}

.header-border{
  border-bottom: 1px solid #ccc;
}

.header-ios ion-toolbar:last-of-type {
  --border-width: 0 0 0;
}

.btn-header{
  --height: 60px;
}