.emergency-call-container {
    ion-fab-button {
        @media screen and (max-width: 300px) {
            height: 70px;
            width: 70px;
        }

        height: 100px;
        width: 100px;

        ion-icon {
            @media screen and (max-width: 300px) {
                font-size: 50px;
            }
            font-size: 70px;
        }
    }


}
.emergencyCallImg {
    margin: 0 auto;
    width: 80px;
    height: 80px;
    cursor: pointer;
    z-index: 999;
    @media screen and (min-height: 1200px) {
        width: 100px;
        height: 100px;
    }
    @media screen and (max-height: 600px) {
        width: 60px;
        height: 60px;
    }
}

.countdown-timer-large {
    font-size: 60px;
}

.speech-modal {
    .ion-page {
        padding-top: 20px;
    }

    .rs-container {
        visibility: hidden;
    }
    
}

.emergency-container{
    flex-grow: 1;
    display: flex;
    align-content: space-between;
    flex-direction: column;
    justify-content: space-between;
    overflow: scroll;
}

.contacts-container{
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
