.ionIcon {
  margin-right: 20px;
  width: 30px;
  height: 30px;
}

.badge {
  z-index: 1;
  border-radius: 50%;
  position: absolute;
  width: 35px;
  height: 25px;
  top: -10px;
  right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.heartIcon {
  float: right;
  width: 40px;
  height: 40px;
  color: #f19300;
}

.cardHeaderPicture{
    width: 100%;
    position: absolute;
    padding: 8px;

}
