.offline{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    background-color: #E6E6E6;
    height: 100%;
    z-index: 30000;
}

.offline > div{
    text-align: center;
}