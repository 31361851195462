.grid{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
  width: 100%;
  overflow: auto;
}
.ionToolbar {
  --background: var(--ion-color-primary);
  color: white;
  display: flex;
  font-size: calc(2vw + 2vh);
  font-weight: bolder;
  text-align: center;
  span {
    font-size: calc(1.8vw + 1.8vh);
    font-weight: normal;
  }
}

.userImage {
  margin: 0 auto;
  width: calc(15vw + 15vh);
  height: calc(15vw + 15vh);

  @media screen and (max-height: 400px) {
    width: calc(10vw + 15vh);
    height: calc(10vw + 15vh);
  }
}

.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bigButton {
  height: 75px !important;
  width: 100%;
  @media screen and (max-width: 550px) {
    height: 55px !important;
  }
  @media screen and (max-height: 450px) {
    height: 55px !important;
  }
  @media screen and (min-width: 1000px) and (min-height: 1000px) {
    height: 140px !important;
    width: 70%;
  }
}

.fontSizeText {
  font-size: calc(1.3vw + 1.3vh);
}

.heightIcon {
  height: 55px;
  width: 55px;
  @media screen and (max-width: 550px) {
    height: 40px;
    width: 40px;
  }
  @media screen and (max-height: 450px) {
    height: 40px;
    width: 40px;
  }
  @media screen and (min-width: 1000px) and (min-height: 1000px) {
    height: 80px;
    width: 80px;
  }
}

.textFooterContainer {
  text-align: center;
  color: white;
  background-color: var(--ion-color-primary);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
}

.textFooter {
  font-size: calc(2vw + 2vh);
  font-weight: bolder;
}
