
.mailbox-message{
    text-align: center;
    .mailbox-message-video-container{
        height: 500px;
        padding: 15px;
        video{
            height: 100%;
            width: 100%; 
        }
    }


   .user-picture{
        img{
            height: 150px;
            width: 150px;
            border-radius: 5px;
            object-fit: cover;
          
        }
    }


}
