.text{
    padding: 20px; 
    text-align: center;
}

.mediaContainer{
    flex-grow: 1;
    height: 100%;
    display: flex;
    justify-content: center;
}

.media{
    max-height: calc(100% - 80px);
}