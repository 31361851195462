.ion-fab {
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  right: 0;
  top: 0;
  height: 100%;
  margin-top: 0px;
  bottom: 0;
  position: unset;
}

.ion-fab-button {
  margin-right: 5px;
  margin-left: 5px;
  width: 60px;
  height: 60px;
  margin-bottom: 10px;
  @media screen and (max-height: 550px) {
    height: 50px;
    width: 50px;
  }
  @media screen and (max-height: 450px) {
    height: 35px;
    width: 35px;
  }
  @media screen and (max-height: 350px) {
    height: 25px;
    width: 25px;
  }
  @media screen and (max-width: 300px) {
    height: 50px;
    width: 50px;
  }
}

.ion-icon {
  width: 30px;
  height: 30px;
  @media screen and (max-height: 550px) {
    height: 25px;
    width: 25px;
  }
  @media screen and (max-height: 450px) {
    height: 20px;
    width: 20px;
  }
  @media screen and (max-height: 350px) {
    height: 15px;
    width: 15px;
  }
}

.ion-fab-item-4 {
  display: flex;
  height: 25%;
  padding: 5px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.ion-fab-item-3 {
  display: flex;
  height: 33.333%;
  padding: 5px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.ion-fab-item-2 {
  display: flex;
  height: 50%;
  padding: 5px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.ion-fab-item-1 {
  display: flex;
  height: 100%;
  padding: 5px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.ion-fab-item-5 {
  display: flex;
  height: 20%;
  padding: 5px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.ion-fab-button-title {
  font-size: var(--ion-subtitle-font-size);
  @media screen and (max-width: 766px) {
    font-size: 12px;
  }
  @media screen and (max-width: 380px) {
    font-size: 10px;
  }
  @media screen and (max-width: 350px) {
    font-size: 8px;
  }
  color: #424242;
}