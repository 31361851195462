.loading{
    position: fixed;
    top: 0;
    bottom: 0;
    left:0;
    right: 0;
    background-color: rgb(255, 255, 255);
    z-index: 10;
    padding-top: 40px;
}

.loading > div{
    text-align: center;
    margin-top: 100px;
    margin-bottom: 30px;
}