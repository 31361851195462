.container{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.list{
    flex-grow: 1;
    width: 100%;
}

.contact-form{
    height: 100%;
    display: flex;
    flex-direction: column;
}

.contact-form-list{
    flex-Grow: 1;
    padding-top: 20px;
    width: 100%;
}

.help-button-icon{
    margin-right: 10px;
}

.help-button-text{
    white-space: normal;
}
.popover-interface .popover-content{
    width: 310px;
}

