.cropper {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
}
  
.crop-container {
  position: relative;
  width: 100%;
  flex-grow: 1;
}
.controls{
  padding: 10px;
}
.controls-item {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

.range{
  --bar-height: 10px;
  --knob-size: 40px;
}

.label{
  color: #f19300;
  margin:0;
}