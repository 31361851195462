
#screensaver{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999999;
    background-color: black;
  
    .awssld__content{
        background-color: black;
    }
  
    .screensaver-item{
        width: 100%;
        background-color: black;
    }

    .blur-background{
        filter: blur(15px);
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    img{
        object-fit: contain;
        filter: none;
        z-index: 1;
    }

    .caption{
        position: absolute;
        padding:15px;
        color:#fff;
        bottom: 20px;
        right: 20px;
        background-color: #423f3f8e;
        border-radius: 10px;
        text-align: right;
        font-size: 2vw;
        z-index: 1;

        p{
            padding: 0;
            margin: 0;
        }

    }

    .clock{
        top:20px;
        left: 20px;
        bottom: auto;
        right: auto;
        text-align: center;
        z-index: 9999999;
        .time{
            font-size: 6vw;
        }
        .date{
            font-size: 3vw;
            text-transform: capitalize;
        }
    }

    .weather{
        top:20px!important;
        padding:0;
        right: 20px!important;
        bottom: auto;
        text-align: center;
        z-index: 9999999;
        font-size: 3vw!important;

    }

    .authorAlbum{
        left: 50%;
        transform: translate(-50%);
        text-align: center;
    }

    .blackscreen{
        background-color: black;
        //background: url("/assets/img/screensaver-moon.jpg");
        //background-size: contain;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        
    }

    .image-disable{
        display: block;
        width: 100vw;
        height: 100vh;
    }   
    
    ion-content::part(background) {
        background: none;
    }

}
        
