#speaker-list {
  overflow: hidden;
  height: 100%;


ion-row{
 margin-bottom: 150px; 
}

  
  .speaker-card {
    display: flex;
    flex-direction: column;
  }


  ion-col:not(:last-of-type) .speaker-card {
    margin-bottom: 0;
  }

  .speaker-card .speaker-item {
    --min-height: 85px;
  }

  .speaker-card .speaker-item h2 {
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 0.02em;
  }

  .speaker-card .speaker-item p {
    font-size: 18px;
    letter-spacing: 0.02em;
  }

  .speaker-card ion-card-header {
    padding: 0;
  }

  .speaker-card ion-card-content {
    flex: 1 1 auto;
    padding: 0;
  }

  .ios ion-list {
    margin-bottom: 10px;
  }

  .md ion-list {
    border-top: 1px solid var(--ion-color-step-150, #d7d8da);

    padding: 0;
  }
}







.header {
  position: fixed;
  top: 0;
  z-index: 1;
  width: 100%;
  background-color: #f1f1f1;
}

.header h2 {
  text-align: center;
}

.progress-container {
  width: 100%;
  height: 14px;
  background: #ccc;
}

.progress-bar {
  height: 8px;
  background: #04AA6D;
  width: 0%;
}

