.fake-link{
    
    text-decoration: underline;
    &:hover{
        cursor: pointer;
    }
}
.cgu-text{
    margin-top: 15px;
}
.ion-activatable {
    .fake-link {
        position: relative;
        z-index: 10;
        margin: 8px;
    }
  }

.new-line{
    word-wrap: break-word;
    display:block;
}

a {
    margin-left: 4px;
    margin-right: 4px;
    text-decoration: none;
}


#intro-text{
    text-align: justify;
  text-justify: inter-word;
  padding: 13px;
  font-size: medium;
}

.image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.item-form {
    margin: 50px 0 20px;
}