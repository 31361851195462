
.video-record{
    height: calc(100vh - 270px);
    width: 100%;
    //object-fit: cover;
    background-color: #666666;
}
.text-record {
	top: 5px;
    position: relative;
}
.counter-record {
   top: 50%;
   left: 50%;
   position:absolute;
   transform: translate(-50%, -50%);
}
.counter-record span{
    font-size: 90px;
    font-weight: bold;
    color: red;
    
}