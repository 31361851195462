.passwordComplexityName
{
    font-size: 40px !important;

}


.toggle-button
{
    margin-top: 7px;
    margin-right: 18px;
    //margin-left: 0px;
  padding-left: 0;
}
