.container {
  display: flex;
  flex-grow: 1;
  width: 80%;
  margin: 50px auto 0px;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 768px) {
    margin: 20px auto 0px;
    width: 100%;
  }
}

.list {
  flex-grow: 1;
  border: 3px solid rgb(212, 57, 116);
  height: max-content;
  @media screen and (max-width: 768px) {
    border: none;
  }
}

.item {
  margin-bottom: 20px;
}

.icon {
  width: 50px;
  height: 50px;
}
