.comment-row {
  display: flex;
  justify-content: flex-start;
}
.comment-row.me {
  justify-content: flex-end !important;
}

.comment-item {
  padding: 10px;
  border-radius: 10px;
  border: solid 1px #aaa;
  width: 60%;
  margin-bottom: 10px;
}
.comment-item.me {
  background-color: black;
  color: white;
}

.comment {
  text-align: left;
  width: 100%;
}
.me .comment {
  text-align: right;
}

.sender {
  font-style: italic;
}
.me > .sender {
  text-align: right;
}

.no-comment {
  text-align: center;
  padding-top: 20px;
}

.comment-section {
  flex-grow: 1;
  overflow: auto;
}

.btn-send {
  text-align: right;
}

.btn-send > ion-button {
  height: 35px !important;
  width: 150px;
}

.comments-content {
  flex-grow: 1;
  position: relative;
  display: flex;
  height: calc(100vh - 260px);
}

.comments-list{
  padding: 10px;
}

.comment-audio{
  width: inherit;
  height: 40px;
}