.supervised-img{
    width: 70px;
    height: 70px;
    border-radius: 50%;
}

.header-content{
    padding: 5px 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    align-items: center;
}

.header-title {
    overflow-wrap: normal!important;
    text-align: center;
    font-weight: bold;
    flex-grow: 1;
}