.icon-size {
  font-size: 55px;
}

.icon-size-mid {
  font-size: 36px;
}

ion-avatar.picture {
  height: 70px;
  width: 70px;
}

ion-avatar.picture > img {
  border-radius: 5px !important;
}

.card-header-text{
  top: 0px;
  left: 0px;
  right: 0px;
  position: absolute;
  background-color: rgba(6, 4, 4, 0.5);
  color: white;
  font-size: 18px;
  padding: 8px;
  padding-top: 10px;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  z-index: 10;
}

.card-header-group{
  top:80px;
  left: 0px;
  position: absolute;
  background-color: rgba(6, 4, 4, 0.5);
  color: white;
  font-size: 18px;
  padding: 8px;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 0 15px 15px 0;
}

.btn-call {
  margin: 0 !important;
}

.btn-remove-friend {
  margin: 0 !important;
  background-color: red !important;
}

.add-friend-modal .ion-page {
  padding: 20px;
}

.speaker-card ion-card-content {
  padding: 0px !important;
}

.speaker-card ion-col {
  padding: 0px !important;
}

.speaker-card ion-button {
  border-radius: 0px !important;
}

ion-checkbox {
  --size: 25px;
}

.selected-card {
  border: 3px solid var(--ion-color-primary);
}

.border-orange {
  border: 3px solid var(--ion-color-primary);
}