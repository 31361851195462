.img {
    margin: 0 auto;
    width: 80px;
    height: 80px;
    cursor: pointer;
    z-index: 999;
    background-color: green;
    padding: 15px;
    border-radius: 50%;
    @media screen and (max-height: 600px) {
        width: 70px;
        height: 70px;
    }
}
