.groupUserPicture {
    width: 150px;
    height: 150px;
    border-radius: 5px;
    object-fit: cover;
    background-color: #00000033;
    margin-bottom: 5px;
  }

  .list-members{
    margin:0;
    text-overflow: ellipsis;
    overflow: hidden; 
    white-space: nowrap;
  }