.section-title{
    font-size: 20px !important;
}
.font-size{
    color: gray;
}

.select-value{
    margin-left: 10px;
}




/*Global font size config*/

$font-size-small-default: 17px;
$font-size-small-lg: 25px;
$font-size-small-sm: 14px;
$font-size-small-xs: 11px;

$font-size-medium-default: 22px;
$font-size-medium-lg: 35px;
$font-size-medium-sm: 19px;
$font-size-medium-xs: 16px;
$font-size-medium-xxs: 14px;

$font-size-large-default: 24px;
$font-size-large-lg: 40px;
$font-size-large-sm: 22px;
$font-size-large-xs: 19px;
$font-size-large-xxs: 16px;

/*small*/
.small-text{
    .countdown-title-large{
        font-size: $font-size-small-lg !important;
        word-wrap: break-word;
    }
    .menu-header-text-size, .card-header-text, .card-header-text-folder, ion-title, h1, input{
        font-size: $font-size-small-default !important ;
        word-wrap: break-word;
    } 
    .menu-header-text-size{
        @media screen and (max-width: 380px) {
            font-size: 16px!important;
        }
        @media screen and (max-width: 320px) {
            font-size: 11px!important;
        }
    }

    ion-card-content, ion-button, ion-label, h2, .header-title-size, .comment, .new-comment, .toast-global, .dropdown-heading-value, ion-input > input::placeholder{
        font-size: $font-size-small-sm !important ;
        word-wrap: break-word;
    } 


    ion-badge, .time-ago-label, .sender{
        font-size: $font-size-small-xs !important ;
        word-wrap: break-word;
    } 
    .subtitle-size{
        font-size: 10px!important ;
        word-wrap: break-word;
        @media screen and (max-width: 304px){
            font-size: 8px;
        }        
    }

}

/*medium*/
.medium-text{
    .countdown-title-large{
        font-size: $font-size-medium-lg !important;
        word-wrap: break-word;
    }
    .card-header-text, .card-header-text-folder, ion-title, h1, input{
        font-size: $font-size-medium-default !important ;
        word-wrap: break-word;
    }
    .menu-header-text-size{
        font-size: $font-size-medium-sm !important ;
        word-wrap: break-word;
        @media screen and (max-width: 380px) {
            font-size: 18px!important;
        }
        @media screen and (max-width: 320px) {
            font-size: 12px!important;
        }
    }

    ion-card-content, ion-button, ion-label, h2, .header-title-size, .comment, .new-comment, .toast-global, .dropdown-heading-value, ion-input > input::placeholder{
        font-size: $font-size-medium-sm !important ;
        word-wrap: break-word;
    } 
    .header-title-size{
        @media screen and (max-width: 380px){
            font-size: $font-size-medium-xs !important;
        }
    }


    ion-badge, .time-ago-label, .sender{
        font-size: $font-size-medium-xs !important ;
        word-wrap: break-word;
    } 
    .subtitle-size{
        font-size: 11px!important ;
        word-wrap: break-word;
        @media screen and (max-width: 304px){
            font-size: 9px;
        }        
    }
}

/*large*/
.large-text{
    .countdown-title-large{
        font-size: $font-size-large-lg !important;
        word-wrap: break-word;
    }
    .card-header-text, .card-header-text-folder, ion-title, h1, input{
        font-size: $font-size-large-default !important ;
        word-wrap: break-word;
    } 
    .menu-header-text-size{
        font-size: $font-size-large-sm !important ;
        word-wrap: break-word;
        @media screen and (max-width: 420px) {
            font-size: 20px!important;
        }
        @media screen and (max-width: 380px) {
            font-size: 16px!important;
        }
        @media screen and (max-width: 320px) {
            font-size: 13px!important;
        }
    }

    ion-card-content, ion-button, ion-label, h2, .header-title-size, .comment, .new-comment, .toast-global, .dropdown-heading-value, ion-input > input::placeholder{
        font-size: $font-size-large-sm !important ;
        word-wrap: break-word;
    }
    .header-title-size{
        @media screen and (max-width: 380px){
            font-size: $font-size-large-xs !important;
        }
    } 


    ion-badge, .time-ago-label, .sender{
        font-size: $font-size-large-xs !important ;
        word-wrap: break-word;
    } 

    .subtitle-size{
        font-size: 12px!important ;
        word-wrap: break-word;
        @media screen and (max-width: 304px){
            font-size: 10px;
        }        
    }
}



/*
.picker-opts-right{
    //padding-right: 20px;
}
.picker-opts-left{
    //padding-left: 20px;
}*/


ion-picker-column
{
    justify-content:center!important;
    .picker-opt{
        width: 100px;
        font-size: 47px;
        font-weight: bold;
        
    }
}

icker-column.picker-opts-right {

    .picker-opts{
        max-width: 200px;
    }

}

ion-picker-column.picker-opts-left 
{
    .picker-opts{
        max-width: 200px;
    }
}

/*
.picker-opts-right
{
    margin-right:50px
}*/

.text-center{
    display: flex;
    align-items: center;
    justify-content: center;   
}
.option-header{
    margin-top: 30px;
    text-align: center;
}

.margin-bottom{
    margin-bottom: 50px;
}
