.center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
  aspect-ratio: 1/1;
  position: relative;
  background: #dddddd;
  border-radius: 50%;
}

.margin{
  margin: 20px;
}

.ionIcon {
  width: 90px;
  height: 90px;
  margin: 20px;

  @media screen and (max-width: 1500px) {
    width: 85px;
    height: 85px;
    margin: 20px;
  }
  @media screen and (max-width: 1000px) {
    width: 80px;
    height: 80px;
    margin: 20px;
  }
  @media screen and (max-width: 800px) {
    width: 80px;
    height: 80px;
    margin: 20px;
  }
  @media screen and (max-width: 500px) {
    width: 80px;
    height: 80px;
    margin: 20px;
  }
  @media screen and (max-width: 400px) {
    width: 60px;
    height: 60px;
    margin: 20px;
  }
  @media screen and (max-height: 800px) {
    width: 70px;
    height: 70px;
    margin: 20px;
  }
  @media screen and (max-height: 680px) {
    width: 30px;
    height: 30px;
    margin: 20px;
  }
  @media screen and (max-height: 580px) {
    width: 30px;
    height: 30px;
    margin: 20px;
  }
  @media screen and (max-width: 350px) {
    width: 50px;
    height: 50px;
    margin: 10px;
  }
  @media screen and (min-height: 1000px) {
    width: 120px;
    height: 120px;
    margin: 20px;
  }
}

.ionCardHeader {
  padding-top: 25px;
  padding-bottom: 25px;
  background-size: cover;
  background-position: center center;
  position: relative;
}

.cardHeaderText {
  height: 100%;
  top: 0px;
  left: 0px;
  right: 0px;
  position: absolute;
  background-color: rgba(6, 4, 4, 0.5);
  color: white;
  font-size: 25px;
  padding: 8px;
  padding-top: 10px;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 500px) {
    font-size: 19px;
  }
  @media screen and (max-width: 400px) {
    font-size: 14px;
  }
  @media screen and (max-width: 350px) {
    font-size: 12px;
  }
}

.ionBadge {
  margin-top: 10px;
  padding: 12px;
  position: absolute;
  right: -10px;
  top: -10px;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 500px) {
    top: -25px;
    right: -10px;
  }

  @media screen and (max-height: 680px) {
    right: -10px;
    top: -19px;
    width: 45px;
    height: 40px;
  }
  @media screen and (max-height: 580px) {
    right: -10px;
    top: -19px;
    width: 45px;
    height: 40px;
  }
  @media screen and (max-width: 350px) {
    right: -2px;
    top: -28px;
    width: 45px;
    height: 40px;
  }
}

.card {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;
}
